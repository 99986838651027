import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="hero is-secondary-blue is-bold is-fullheight">
      <div className="hero-body">
        <div className="container">
          <h1 className="title is-1">Ricardo Garcia Silva</h1>
          <h3 className="subtitle is-3">
            I love to build useful software that enables spatial interactions
          </h3>
          <p className="subtitle is-5">
            Welcome to my digital warehouse where I post information about me or
            the things that interest me
          </p>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
